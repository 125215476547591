@use "sass:math";

.p-tag {
  background: $badgeBg;
  color: $badgeTextColor;
  font-size: $badgeFontSize;
  font-weight: $badgeFontWeight;
  padding: $tagPadding;
  border-radius: $borderRadius;

  &.p-tag-success {
    background-color: $successButtonBg;
    color: $successButtonTextColor;
  }

  &.p-tag-info {
    background-color: $infoButtonBg;
    color: $infoButtonTextColor;
  }

  &.p-tag-warning {
    background-color: $warningButtonBg;
    color: $warningButtonTextColor;
  }

  &.p-tag-danger {
    background-color: $dangerButtonBg;
    color: $dangerButtonTextColor;
  }

  .p-tag-icon {
    margin-right: math.div($inlineSpacing, 2);
    font-size: $badgeFontSize;
  }

  .p-icon {
    width: $badgeFontSize;
    height: $badgeFontSize;
  }
}
