@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("./fonts/Inter-Light.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/Inter-Light.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/Inter-Regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/Inter-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./fonts/Inter-Medium.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/Inter-Medium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/Inter-SemiBold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/Inter-SemiBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/Inter-Bold.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/Inter-Bold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
