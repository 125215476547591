/* You can add global styles to this file, and also import other style files */

@import "variables.scss";
@import "styles/fontawesome/fontawesome.scss";
@import "styles/fontawesome/light.scss";
@import "styles/fontawesome/regular.scss";
@import "styles/fontawesome/solid.scss";

@import "primeflex/primeflex.scss";
@import "~primeicons/primeicons.css";

// @@@NEW-PROJECT@@@ This is the default theme used in the seed.
// You can change the them from one in the list:
// [arya, fluent, luna, mira, nano, rhea, soho, vela, bootstrap4, lara, material, mytheme, nova, saga, tailwind, viva]
@import "src/sass/themes/tailwind/tailwind-light/theme.scss";
@import "~primeng/resources/primeng.min.css";
@import "styles/panel-menu.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--primaryFontFamily);
  font-size: 100%;
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 400;
  color: $primaryColor;
  background-color: $primaryTextColor;
}

/***
  GLOBAL SCSS
  ***/

.p-component {
  font-family: var(--primaryFontFamily);
}

.custom-tooltip {
  .p-tooltip-text {
    max-width: 900px;
    width: max-content;
  }
}

.custom-input {
  width: 285px !important;
}

.border-red {
  &:hover {
    input {
      border: solid 1px red;
    }
  }
  input {
    border: solid 1px red;
  }
}

.text-lg {
  font-size: 1.1rem;
}

.text-style {
  font-size: 16px;
  color: $primaryColor;
}

.form-hidden {
  height: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.facebook {
  font-size: 32px;
  color: #3b5998;
  background: white;
}

.avatar {
  width: 40px;
  border-radius: 20px;
}

.font-bold-blue {
  font-size: 16px;
  color: $primaryColor;
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 600;
}

h1 {
  font-size: 24px;
  color: $primaryColor;
}

h2 {
  font-size: 20px;
  color: $primaryColor;
}

h3 {
  font-size: 16px;
  color: $primaryColor;
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 400;
}

//table
table {
  width: 100%;
}

.p-menuitem-link-active {
  background: #ededed;
}

.p-button {
  padding: 0.5rem 1rem;
}

.wrap-padding {
  padding: 2px;
}

.space-b {
  justify-content: space-between;
  align-items: baseline;
}

/***
  CUSTOM PRIMENG SCSS
  ***/

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 1px #114559;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 1px #114559;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 1px #114559;
}

.p-button-sm {
  font-size: 1rem !important;
  padding: 0.5rem 1rem !important;
}

.p-button-rounded {
  border-radius: 2rem !important;
  button {
    border-radius: 2rem !important;
  }
}

.p-menubar {
  padding: 0.5rem;
  border-radius: 0;
}

.p-menubar .p-menubar-custom,
.p-menubar .p-menubar-end {
  display: flex;
  align-items: center !important;
}

.p-button:focus {
  outline: none;
  box-shadow: none;
}

.p-input-icon-left,
.p-input-icon-right {
  position: relative;
  display: inline-block;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-multiselect {
  min-width: 200px;
}

.p-multiselect-label {
  display: flex !important;
  gap: 5px;
}

.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem;
}

.notif-error-white-space {
  white-space: pre-line;
}

.p-column-filter-add-rule {
  padding: 0 20px 0 20px !important;
}
.p-column-filter-add-button {
  padding: 0 !important;
}
.p-column-filter-remove-button {
  padding: 0 !important;
}

a {
  color: $primaryColor;
  text-decoration: none !important;
}

.p-card {
  border-radius: 3.375rem;

  .p-card-body {
    padding: 1.25rem;
    background-color: $primaryTextColor;
    border: 10px solid $primaryColor;
    border-radius: 50px;
    color: $primaryColor;
  }
}

.p-button.p-button-icon-only {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #f4f4f5;
  color: black;
}

.button-danger-hover {
  .p-button:not(:disabled):hover {
    color: $red !important;
    background: #ffbfbf !important;
    border: solid 1px #ffbfbf !important;
  }
}

.button-check-hover {
  .p-button:not(:disabled):hover {
    color: green !important;
    background: $green !important;
    border: solid 1px #ade3bc !important;
  }
}

//------------------------Main Wrapper---------------------------------------->

.remove-scroll {
  //To fix the double scroll
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scroll {
  overflow-y: scroll;
  margin-top: 90px;
  height: 100%;
}

.footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1250px) {
  .main {
    .bg-illu {
      width: 100%;
      margin-top: 31px;
    }

    .info-form {
      .welcome-message {
        margin-bottom: 2rem;
      }
    }
  }
}

.p-overlaypanel {
  margin-top: 0px;
}

//------------------------Header------------------------------>

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: calc(100% - $leftPanelWidth);
  height: 90px;
  border-bottom: 1px solid rgba(18, 69, 89, 0.5);
  padding: 20px;
  background-color: #fafafa;
  z-index: 100;

  .current-tab {
    align-items: center;
    flex-direction: row;
    gap: 20px;
    color: $primaryColor;
    text-transform: uppercase;
    display: flex;
    font-variation-settings:
      "slnt" 0,
      "MONO" 0,
      "wght" 600;
  }
}

.header {
  .p-dropdown {
    background: #efeee1;
    color: $primaryColor;
    text-transform: uppercase;
    font-variation-settings:
      "slnt" 0,
      "MONO" 0,
      "wght" 600;
  }
  .p-component {
    font-size: 14px;
  }
}

//------------------------------Tab--------------------------------->

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 600;
}

.p-tabview .p-tabview-nav {
  background-color: $highlightBg;
  padding-left: 0rem !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

//--------------------------Folder table----------------------------->

.document-table {
  .p-datatable-header {
    background: white !important;
    border: none !important;
    padding: 0px 0px 0px 0px !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.2rem 1.5rem !important;
    font-size: 14px;

    .p-button {
      padding: 0px 0px 0px 0px;
    }
  }

  .p-inputtext-sm .p-inputtext {
    font-size: 14px;
    padding: 5px 10px;
    border: none;
  }

  .p-button-label {
    font-size: 14px;
    font-weight: 400;
  }

  .p-datatable .p-datatable-thead > tr > th {
    padding: 0.3rem 1.5rem;
    color: $primaryColor;
  }
}

.demo-table {
  .p-datatable-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: white !important;
    border: none !important;
    padding: 0px 0px 10px 0px !important;
    font-size: 16px;

    .p-inputtext {
      border-radius: 2.2rem;
    }
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    border: 1px solid $primaryColor;
    border-width: 0 0 1px 0;
    font-variation-settings:
      "slnt" 0,
      "MONO" 0,
      "wght" 600;
    color: $primaryColor;
    background: none;
    transition: none;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    color: $primaryColor;
  }
}

.admin-table {
  .p-datatable .p-datatable-tbody > tr > td {
    min-width: 30vw;
  }
}

.p-column-filter-buttonbar {
  justify-content: left !important;

  .p-button {
    margin-right: 10px !important;
    border-radius: 25px;
  }
}

//--------------------------General--------------------------------->

.p-inputtext {
  border-radius: 0.2rem;
  color: $primaryColor;
  font-family: var(--primaryFontFamily);
  font-size: 16px;
  padding: 0.5rem 1rem;
}

.p-menu,
.p-overlay,
.p-dropdown {
  ol,
  ul {
    padding-left: 0rem;
  }
}

.p-dropdown {
  border-radius: 0.2rem;
  border: 1px solid $primaryDarkColor;
  color: $primaryColor;
  font-family: var(--primaryFontFamily);
  font-size: 16px;
  min-height: 33px;
}

.p-dropdown-document {
  .p-dropdown {
    min-width: 315px;
  }
}

.button-legend {
  .far,
  .fa-regular {
    font-size: 22px;
  }
}

.p-overlaypanel .p-overlaypanel-content {
  -webkit-box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
}

.p-button-label {
  font-weight: 400 !important;
}

.p-button.p-button-icon-only.p-button-rounded {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.p-scrollpanel-bar {
  background: var(--primaryBlue) !important;
}

.full-width {
  width: 100%;
}

.middle-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.box-input {
  width: 15%;
  display: flex;
  flex-direction: column;

  .p-dropdown,
  .p-calendar {
    width: 100% !important;
    min-width: 200px;
  }
}

.dropdown-style {
  .p-component {
    font-family: var(--primaryFontFamily);
    font-size: 16px !important;
    width: 13vw !important;
    min-height: 36px;
  }
  .p-inputtext {
    border: none;
  }
}

.dropdown-style-support {
  .p-component {
    width: 100% !important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px !important;
}

.admin-button {
  .p-button.p-button-rounded {
    width: 180px;
  }
}

.import-button {
  .p-button,
  .p-fileupload-choose {
    border-radius: 2.375rem;
    padding: 0.5rem 1rem;
  }
}

.document-table .p-button-label {
  font-size: 16px;
}

.calendar-style {
  .p-calendar {
    width: 13vw;

    .p-inputtext {
      border-radius: 0.2rem 0px 0px 0.2rem;
      border: 1px solid #114559;
    }
  }
  .p-button {
    padding: 0px 0px 0px 0px;
    border-radius: 0px 0.2rem 0.2rem 0px;
  }
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.p-button.p-button-danger {
  color: #fd656c;
  background: #ffbfbf;
  border: none;
}

.action-button {
  display: flex;
  gap: 10px;
}

.text-ellipsis {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-border {
  border: 1px solid #598392;
}

//------------------------------Tab------------------------------->

#wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.wrapper-header {
  width: 91vw;
  min-height: 125px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 156px;
  z-index: 1001;
  padding: 15px 10px 10px 4px;
}

.wrapper-header-dossier {
  display: grid;
  grid-template-columns: 1fr;
  padding-right: 35px;
}

.wrapper-header-tab {
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 250px);
  padding-right: 20px;
  padding-top: 10px;

  table {
    width: 40vw;
  }

  td {
    font-weight: 400;
  }

  th {
    font-weight: 500;
  }
}

.field {
  height: 100px;
  margin-bottom: 0rem !important;

  .p-button-label {
    font-weight: 400;
  }
}

.field-custom {
  .p-inputtext.p-inputtext-sm {
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #598392;
  }

  .mb-1 {
    margin-bottom: 0.1rem !important;
  }
}

.line-style {
  height: 80px;
  gap: 1.2vw;

  .p-inputtext-sm .p-inputtext {
    padding: 5px 10px;
  }
}

//---------------------------Rules-lines---------------------------->

.main-wrapper {
  padding-top: 105px;
}

.grid-line {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 230px 1fr;
  grid-template-areas: "left-part middle right-part";
  gap: 0;
  height: 100%;
  font-size: 14px;
  padding: 4px 20px 0 20px;

  .left-part {
    grid-area: left-part;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: var(--heightLine);
    padding: 8px 12px;
    border-radius: 0.3rem;
  }

  .content-top {
    align-items: flex-start;
  }

  .middle {
    height: 60px;
    grid-area: middle;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.3rem;
    margin: 0 4px 0 4px;

    .demi-circle-left {
      height: 1rem;
      width: 1.25rem;
      border-radius: 50%;
      z-index: 1;
      margin-left: -13px;
      border: none;
    }

    .demi-circle-right {
      @extend .demi-circle-left;
      margin-left: 0px;
      margin-right: -13px;
    }
  }

  .right-part {
    grid-area: right-part;
    display: flex;
    height: var(--heightLine);
    padding: 8px 12px;
    border-radius: 0.3rem;
  }

  .constant-right {
    grid-area: right-part;
    display: flex;
    align-items: center;
    height: var(--heightLine);
    padding: 8px 16px;
    border-radius: 0.3rem;
  }

  .tags-box {
    position: absolute;
    top: 4px;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background: #e0dfdf;
    width: 175px;
    height: var(--heightLine);
    padding: 4px 2px;
    border-radius: 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    opacity: 50%;

    &:hover {
      min-height: 100%;
      height: fit-content;
      white-space: pre-wrap;
      overflow: visible;
      -webkit-box-shadow: 0px 8px 10px 1px rgba(18, 69, 89, 0.72);
      -moz-box-shadow: 0px 8px 10px 1px rgba(18, 69, 89, 0.72);
      box-shadow: 0px 8px 10px 1px rgba(18, 69, 89, 0.72);
      z-index: 1;
    }
  }
  // todo: Remove if tags are completely removed
  // .tag {
  //   display: flex;
  //   text-align: center;
  //   align-items: center;
  //   width: fit-content;
  //   grid-area: filters;
  //   text-transform: uppercase;
  //   color: var(--primaryBlue);
  //   background-color: white;
  //   border-top-right-radius: 0.5rem;
  //   font-size: 10px;
  //   padding: 4px;
  //   margin: 2px;
  // }

  .infos-container {
    height: var(--heightVariable);
    max-width: 250px;
    min-width: 250px;
    border-radius: 12px;
    padding: 2px;

    .variable {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      width: 100%;
      height: var(--heightVariable);

      border-radius: 12px;
      padding: 5px 10px;
      font-size: 12px;
      text-align: left;

      &:hover {
        height: fit-content;
        z-index: 2;
        position: relative;

        .ellipsis {
          white-space: pre-wrap;
          overflow: visible;
          text-overflow: none;
        }
      }
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.button-folder {
  width: 3.5rem;
}

//---------------------------Colours------------------------->

.red {
  background: $red;
}

.light-red {
  background: var(--lightRedBis);
}

.green {
  background: $green;
}

.dark-green {
  background: $dark-green;
}

.turquoise {
  background: $turquoise;
}

.dark-turquoise {
  background: $dark-turquoise;
}

.blue {
  background: $blue;
}

.light-grey {
  background: $grey;
}

.grey {
  background: $grey;
}

.dark-grey {
  background: $dark-grey;
}

.pink {
  background: $pink;
}

.dark-pink {
  background: $dark-pink;
}

.orange {
  background: $orange;
}

.dark-orange {
  background: $dark-orange;
}

.beige {
  background: $beige;
}

.operator-border-red {
  background: $beige;
  border: 3px solid $red;
}

.operator-border-green {
  background: $beige;
  border: 3px solid var(--veryDarkGreen);
}

.red-button {
  background: $red;
  border: none;
}

.green-button {
  background: $green;
  border: none;
}

//--------------------------Progress bar---------------------->

.progression-info {
  font-size: 12px;
}
.progress-bar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #c5c3c6;
  height: 1rem;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.progress-bar__inner_1 {
  text-align: center;
  position: relative;
  display: block;
  height: 100%;
  background-color: $red;
  // animation: run-progress 1s;
}

.progress-bar__inner_2 {
  text-align: center;
  position: relative;
  display: block;
  height: 100%;
  background-color: $green;
  // animation: run-progress 1s;
}

.progress-bar__inner_3 {
  text-align: center;
  position: relative;
  display: block;
  height: 100%;
  background-color: $dark-grey;
  // animation: run-progress 1s;
}

@keyframes run-progress {
  0% {
    width: 0;
  }
}

// Progress bar upload fichier
.pb-style {
  .p-progressbar {
    border-radius: 15px;
  }
}

// Pdf viewer
.input-custom {
  .p-inputtext {
    padding: 5px 10px;
  }
}

.higlight-custom {
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #f4f4f5;
  }
}

.toolbarButton {
  color: #ffffff !important;
  border-radius: 5px !important;
  background-color: var(--primaryBlue) !important;
  width: 50px !important;
  height: 22px !important;
  margin: 4px 0px 4px 0 !important;
}

.button:focus {
  border: none !important;
}

.toolbarField {
  border-color: rgb(17 69 89) !important;
  color: var(--primaryBlue) !important;
  font-family:
    avenir next,
    sans-serif !important;
  margin: 4px 5px 4px 0 !important;
}

#findPrevious {
  margin-top: 3px !important;
  width: 24px !important;
  margin-left: 1px !important;
  margin-right: 10px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#findNext {
  margin-top: 3px !important;
  width: 24px !important;
  margin-left: 1px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#toolbarViewerLeft {
  margin-left: 0px !important;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

#toolbarViewerRight {
  margin-right: 0px !important;
}

#toolbarViewerMiddle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
}

.reset-width-padding {
  padding-top: 0px !important;
}

.toolbarLabel {
  margin: 4px 4px 4px 0 !important;
}

#findResultsCount {
  background-color: var(--primaryBlue) !important;
  color: white !important;
}

.upload-custom {
  font-size: 30px;
  color: #124559;
}

.extension-text {
  color: #124559;
}

.drag-drop-style {
  border: 2px dashed #124559;
  border-radius: 10px;
}

.pdf-viewer {
  .p-dialog {
    max-height: 97% !important;
  }
}

// Datatable modal import documents fix scroll height
@media screen and (max-width: 1440px) {
  .p-datatable-scrollable > .p-datatable-wrapper {
    max-height: 26vh !important;
  }
}

// Cursors
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fa-warning {
  color: red;
}

.line-separator {
  border: 1px solid #e5e7eb;
  margin-bottom: 20px;
}

// -------------------------- Guided tour -------------------------- //
// Guided Tour library --> https://shepherdjs.dev/docs
// allows to use break space in translation
// https://github.com/angular-translate/angular-translate/issues/595

.allow-to-use-break-space-in-translation {
  white-space: pre-line;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-5 {
  padding: 5px;
}

.padding-rule-line {
  padding: 0px 5px 5px 0px;
}

.custom-default-class {
  background-color: red;
}

.gd-title {
  color: var(--primaryTextColor);
  font-size: 20px;
  font-weight: 500;
}

.gd-subtitle {
  color: var(--primaryBlue);
  font-size: 20px;
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 600;
}

.gd-button {
  background: var(--secondaryBlue);
  border-color: var(--secondaryBlue);
}

.gd-custom-button {
  background: var(--primaryBlue) !important;
  border-radius: 2rem !important;
  font-weight: 400 !important;
  color: var(--primaryTextColor) !important;
}

.gd-color-blue {
  color: var(--primaryBlue);
}

.gd-color-green {
  color: var(--darkGreen);
}

.gd-color-grey {
  color: var(--grey);
}

.shepherd-element {
  border-radius: 30px !important;
}

.shepherd-text {
  padding: 1em 1em 2em 1rem !important;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: var(--primaryBlue) !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.shepherd-element.shepherd-has-title[data-popper-placement^="bottom"] > .shepherd-arrow:before {
  background-color: var(--primaryBlue) !important;
}

.shepherd-cancel-icon {
  color: white;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: #f4f4f5 !important;
}

.gd-icon {
  font-size: 22px;
}

.gd-icon-small {
  font-size: 20px;
}

.gd-line {
  border-bottom: 1px solid var(--lightGrey);
  padding-bottom: 10px;
}

.banner-env {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryTextColor);
  color: var(--primaryBlue);
  border-left: 1px solid var(--primaryBlue);
  border-right: 1px solid var(--primaryBlue);
  border-bottom: 1px solid var(--primaryBlue);
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  z-index: 1000;
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

//--------------------------SUPER DOSSIER---------------------->

.tab-superdossier {
  .p-tabview-nav-container {
    position: relative;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 2;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0px;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.padding-20 {
  padding: 20px;
}

.create-tab-button {
  width: 60px;
  height: 64px;

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0rem !important;
  }
}

.label {
  font-size: 16px;
  font-variation-settings:
    "slnt" 0,
    "MONO" 0,
    "wght" 600;
  color: var(--primaryBlue);
}

.border-info-tab {
  border-left: 2px solid #e5e7eb;
  padding-left: 30px;
}

.p-menu .p-menuitem > .p-menuitem-content {
  padding: 10px 20px 10px 20px;
  color: var(--primaryBlue);
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  color: var(--primaryBlue);
  background: #efeee1;
}

//--------------------------NEW CHARTE---------------------->

.lang-switcher-custom {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10001;

  .p-dropdown .p-dropdown-trigger {
    color: white;
  }

  .p-dropdown {
    background: transparent;
    border: 1px solid white;
  }

  .p-inputtext {
    color: white;
  }
}

.warning-message {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 5px;
  color: #044560;
}

.terms-and-conditions {
  .p-button-label {
    font-weight: 800 !important;
    font-size: 14px;
    text-decoration: underline !important;
  }
  .p-button {
    color: #044560;
    background: #efeee1;
    border: 1px solid #efeee1;
    padding: 0rem 0rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
  }
}

//--------------------------NEW RULES PAGE---------------------->

.p-accordion .p-accordion-content {
  padding: 0px;
  border: none;
}

// Positioning the accordion arrow to the right
.p-accordion .p-accordion-header .p-accordion-header-link {
  flex-direction: row-reverse;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
  border: none;
  background: #efeee0;
  border-radius: 0.375rem 0 0 0.375rem !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-left: 1rem;
  margin-right: 1rem;
  position: absolute;
  right: 82px;
}
